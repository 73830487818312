import { Route } from './route'

export type Vehicle = {
  make: string
  current_status: string
  current_cargo: number
  current_driver: string
  created: Date
  live_location: any
  license_plate: string
  next_stop_address: string
  phone_number: string
  id: number
  next_stop_time: string
  km: number
  name: string
  model: string
  updated: Date
  capacity: number
  current_order: number
  registration_date: Date
  order_type: number
  depot_id: string
  driver_id: number
  area_id: number
  area_name: string
}

export type Status = 'ACTIVE' | 'ONROUTE' | 'INACTIVE' | 'REST'
export const VEHICLE_STATUSES = {
  ACTIVE: '待機',
  ONROUTE: '運行中',
  INACTIVE: '運行終了',
  REST: '休憩'
}

export const statusToString = (status: Status) => {
  return VEHICLE_STATUSES[status]
}

export const getVehicleRoutes = (
  $axios: any,
  vehicleId: number,
  the_date: string
) => {
  return $axios
    .$get(`/vehicles/${vehicleId}/routes/?date=${the_date}`) // todo: replace with "today"
    .then((res: Route[]) => {
      res.forEach((route: Route) => {
        route.order = undefined
      })
      return res
    })
}

export const getAllVehicles = (axios: any) => {
  return axios.$get('/vehicles/')
}

export const getByDriver = (
  $axios: any,
  driverId: number
): Promise<Vehicle> => {
  return $axios
    .$get(`/vehicles/?filter=driver_id eq ${driverId}`)
    .then((vehicles: Vehicle[]) => vehicles[0])
}

export const remove = ($axios: any, vehicleId: number) => {
  return $axios.$delete(`/vehicles/${vehicleId}`)
}

export const getVehiclesAvailabilities = (
  $axios: any,
  vehicleId: number,
  the_date: string
) => {
  return $axios.$get(`/vehicles/${vehicleId}/availabilities/?date=${the_date}`)
}
